import React from 'react';
import cn from 'classnames';
import * as styles from './partners-section.module.scss';
import { useTranslation } from 'react-i18next';

export const PartnersSection = (props) => {
  const { section } = props;
  const { t } = useTranslation();

  return (
    <div className="bg-white" id={t(section.id)}>
      <div className="container">
        <div className={cn('row', styles.container)}>
          <div className="col-lg-12">
            {section.title && (
              <h2 className={styles.title}>{t(section.title)}</h2>
            )}
          </div>
          <div className="col-lg-12">
            <div className={styles.logos}>
              {section.list.map((item) => (
                <div className={styles.logo}>
                  <img
                    loading="lazy"
                    className="img-fluid w-100"
                    src={item.image}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
