import React, { useContext } from 'react';
import cn from 'classnames';
import * as styles from './gallery-section.module.scss';
import { useTranslation } from 'react-i18next';
import { LanguageContext } from './Layout';
import { Link } from 'gatsby';
import { localizeURL } from '../utils/url';

export const GallerySection = (props) => {
  const { section } = props;
  const { t } = useTranslation();
  const language = useContext(LanguageContext);

  return (
    <div className="bg-grey" id={t(section.id)}>
      <div className={styles.container}>
        <div className="container">
          {section.list.map((item, index) => {
            const isOdd = index % 2 === 1;
            const contentClass = isOdd
              ? 'col-lg-4 offset-lg-2'
              : 'col-lg-4 offset-lg-1';
            const content = (
              <div className={contentClass}>
                {item.title && (
                  <div className={styles.name}>{t(item.title)}</div>
                )}
                {item.subtitle && (
                  <div className={styles.description}>{t(item.subtitle)}</div>
                )}
                {item.buttonLink && item.buttonText && (
                  <Link
                    to={localizeURL(language, item.buttonLink)}
                    className={cn('btn btn-primary', styles.button)}
                  >
                    {t(item.buttonText)}
                  </Link>
                )}
              </div>
            );
            const image = (
              <div className="col-lg-6">
                <img
                  src={item.image}
                  alt=""
                  className={cn('img-fluid w-100', styles.image)}
                />
              </div>
            );
            const children = [content, image];

            return (
              <div
                className={cn(
                  'row align-items-center',
                  styles.section,
                  isOdd && styles.sectionOdd,
                )}
              >
                {isOdd ? children : children.reverse()}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
