// extracted by mini-css-extract-plugin
export var arrow = "form-section-module--arrow--Yw2Y0";
export var buttonContainer = "form-section-module--buttonContainer--nohoz";
export var container = "form-section-module--container--PuMaJ";
export var input = "form-section-module--input--+Lcpn";
export var label = "form-section-module--label--cK9Wj";
export var privacy = "form-section-module--privacy--NkBgT";
export var select = "form-section-module--select--ydUci";
export var social = "form-section-module--social--2Vt7e";
export var socialIcon = "form-section-module--socialIcon--2cCf6";
export var socialLink = "form-section-module--socialLink--f8KIm";
export var subtitle = "form-section-module--subtitle--+-URw";
export var textarea = "form-section-module--textarea--Il-4A";
export var title = "form-section-module--title--YEGHb";
export var titleSub = "form-section-module--titleSub--xwVvq";