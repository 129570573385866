import React, { useContext } from 'react';
import cn from 'classnames';
import * as styles from './quote-section.module.scss';
import { useTranslation } from 'react-i18next';
import { LanguageContext } from './Layout';
import { Link } from 'gatsby';
import { localizeURL } from '../utils/url';

export const QuoteSection = (props) => {
  const { section } = props;
  const { t } = useTranslation();
  const language = useContext(LanguageContext);

  return (
    <div className="bg-light-grey" id={t(section.id)}>
      <div className="container">
        <div className={cn('row', styles.container)}>
          <div className="col-lg-12">
            {section.title && (
              <h2 className={styles.title}>{t(section.title)}</h2>
            )}
            {section.subtitle && (
              <div className={styles.subtitle}>{t(section.subtitle)}</div>
            )}
            {section.buttonLink && section.buttonText && (
              <div className={styles.buttonContainer}>
                <Link
                  to={localizeURL(language, section.buttonLink)}
                  className={cn('btn btn-primary', styles.button)}
                >
                  {t(section.buttonText)}
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
