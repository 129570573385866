import React, { useContext } from 'react';
import cn from 'classnames';
import * as styles from './cards-section.module.scss';
import { useTranslation } from 'react-i18next';
import { LanguageContext } from './Layout';
import { Link } from 'gatsby';
import { localizeURL } from '../utils/url';

export const CardsSection = (props) => {
  const { section } = props;
  const { t } = useTranslation();
  const language = useContext(LanguageContext);

  return (
    <div className="bg-grey" id={t(section.id)}>
      <div className="container">
        <div className={cn('row', styles.container)}>
          <div className="col-lg-8 offset-lg-2">
            {section.title && (
              <h2 className={styles.title}>{t(section.title)}</h2>
            )}
          </div>
          <div className="col-lg-12">
            <div className={styles.cards}>
              {section.list.map((item, index) => {
                const isOdd = index % 2 === 1;

                return (
                  <div>
                    <div
                      className={cn(styles.card, isOdd && styles.cardOffset)}
                    >
                      <div className={styles.info}>
                        <div className={styles.imageContainer}>
                          <img
                            src={item.image}
                            alt=""
                            className={styles.image}
                          />
                        </div>
                        {item.title && (
                          <div className={styles.name}>{t(item.title)}</div>
                        )}
                      </div>
                      {item.subtitle && (
                        <div className={styles.text}>{t(item.subtitle)}</div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>

            {section.buttonLink && section.buttonText && (
              <div className={styles.buttonContainer}>
                <Link
                  to={localizeURL(language, section.buttonLink)}
                  className={cn('btn btn-primary', styles.button)}
                >
                  {t(section.buttonText)}
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
