import React from 'react';
import cn from 'classnames';
import * as styles from './logo-section.module.scss';
import { useTranslation } from 'react-i18next';

export const LogoSection = (props) => {
  const { section } = props;
  const { t } = useTranslation();

  return (
    <div className="bg-grey" id={t(section.id)}>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className={cn(styles.container)}>
              {section.list.map((item) => (
                <div className="text-center">
                  <img loading="lazy" className="img-fluid" src={item.image} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
