import React, { useContext } from 'react';
import cn from 'classnames';
import * as styles from './products-section.module.scss';
import { useTranslation } from 'react-i18next';
import { LanguageContext } from './Layout';
import { Link } from 'gatsby';
import { localizeURL } from '../utils/url';

export const ProductsSection = (props) => {
  const { section } = props;
  const { t } = useTranslation();
  const language = useContext(LanguageContext);

  return (
    <div className="bg-grey" id={t(section.id)}>
      <div className="container">
        <div className={cn('row', styles.container)}>
          {true && (
            <div className="col-lg-12">
              {section.title && (
                <h2 className={styles.title}>{t(section.title)}</h2>
              )}
            </div>
          )}
          <div className="col-lg-12">
            <div className={styles.cards}>
              {section.list.map((item) => (
                <a href={t(item.link)} className={styles.link}>
                  <div className={styles.card}>
                    <div className={styles.imageContainer}>
                      <img
                        loading="lazy"
                        src={item.image}
                        className={styles.image}
                      />
                    </div>
                    {item.title && (
                      <div className={styles.text}>{t(item.title)}</div>
                    )}
                  </div>
                </a>
              ))}
            </div>
          </div>
          <div className="col-lg-12">
            {section.buttonLink && section.buttonText && (
              <div className={styles.buttonContainer}>
                <Link
                  to={localizeURL(language, section.buttonLink)}
                  className={cn('btn btn-primary', styles.button)}
                >
                  {t(section.buttonText)}
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
