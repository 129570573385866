import React from 'react';
import cn from 'classnames';
import * as styles from './form-section.module.scss';
import { useTranslation } from 'react-i18next';

export const FormSection = (props) => {
  const { section } = props;
  const form = section.form || 'contact';
  const { t } = useTranslation();

  return (
    <div className="bg-white" id={t(section.id)}>
      <div className={styles.container}>
        <form name={form} action="" method="POST" data-netlify="true">
          <input type="hidden" name="form-name" value={form} />
          <div className="container">
            {section.title && (
              <div className="row">
                <div className="col-lg-12">
                  <h2
                    className={cn(
                      styles.title,
                      (section.subtitle ||
                        (section.linkedin && section.name)) &&
                        styles.titleSub,
                    )}
                  >
                    {t(section.title)}
                  </h2>
                </div>
              </div>
            )}
            {section.subtitle && (
              <div className={styles.subtitle}>{t(section.subtitle)}</div>
            )}
            {section.linkedin && section.name && (
              <div className={styles.social}>
                <div className={styles.socialIcon}>
                  <svg
                    width="29"
                    height="29"
                    viewBox="0 0 29 29"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.59519 11.0476V22.0952M21.4047 22.0952V16.5714C21.4047 13.902 19.2408 11.738 16.5714 11.738C13.902 11.738 11.738 13.902 11.738 16.5714V22.0952V11.0476M6.90472 7.59519H8.28567M2.07138 0.69043H26.9285C27.6912 0.69043 28.3095 1.3087 28.3095 2.07138V26.9285C28.3095 27.6912 27.6912 28.3095 26.9285 28.3095H2.07138C1.3087 28.3095 0.69043 27.6912 0.69043 26.9285V2.07138C0.69043 1.3087 1.3087 0.69043 2.07138 0.69043Z"
                      stroke="#0057FF"
                    />
                  </svg>
                </div>
                <a href={t(section.linkedin)} className={styles.socialLink}>
                  {t(section.name)}
                </a>
              </div>
            )}
            {section.fields.map((field, index) => {
              const star = field.required ? '*' : '';
              const label = `${t(field.label)}${star}`;

              if (field.type === 'message') {
                return (
                  <div className="row" key={index}>
                    <div className="col-lg-10">
                      <label className={styles.label}>{label}</label>
                      <textarea
                        required={field.required}
                        className={styles.textarea}
                        name={field.name}
                      />
                    </div>
                  </div>
                );
              }

              if (field.type === 'select') {
                return (
                  <div className="row" key={index}>
                    <div className="col-lg-6 position-relative">
                      <svg
                        width="29"
                        height="15"
                        viewBox="0 0 29 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className={styles.arrow}
                      >
                        <path d="M1 1L15 14L28 1" stroke="black" />
                      </svg>

                      <select
                        type="text"
                        required={field.required}
                        name={field.name}
                        className={cn(styles.input, styles.select)}
                        defaultValue=""
                      >
                        <option value="" disabled>
                          {label} {t('(select)')}
                        </option>
                        {field.values?.map?.((value, index) => (
                          <option value={t(value)} key={index}>
                            {t(value)}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                );
              }

              return (
                <div className="row" key={index}>
                  <div className="col-lg-6">
                    <input
                      type="text"
                      name={field.name}
                      placeholder={label}
                      className={styles.input}
                      required={field.required}
                    />
                  </div>
                </div>
              );
            })}
            {section.privacy && (
              <div className="row">
                <div className="col-lg-6">
                  <div className={styles.privacy}>{t(section.privacy)}</div>
                </div>
              </div>
            )}
            {section.button && (
              <div className="row">
                <div className="col-lg-12">
                  <div className={styles.buttonContainer}>
                    <button className={cn('btn btn-primary', styles.button)}>
                      {t(section.button)}
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};
