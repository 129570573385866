import React, { useContext } from 'react';
import _ from 'lodash';
import * as styles from './header.module.scss';
import cn from 'classnames';
import { withPrefix, classNames } from '../utils';
import { Link } from 'gatsby';
import Action from './Action';
import { useTranslation } from 'react-i18next';
import { LanguageContext } from './Layout';
import { linkToURL, localizeURL } from '../utils/url';

export default (props) => {
  const { t } = useTranslation();

  const language = useContext(LanguageContext);

  return (
    <>
      <div className={styles.prefix}></div>
      <nav className={cn('navbar navbar-expand-lg navbar-dark', styles.header)}>
        <div className="container">
          <Link className="navbar-brand" to={localizeURL(language, '/')}>
            <svg
              width="145"
              height="30"
              viewBox="0 0 145 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M140.757 5.03318L141.76 4.82152C141.812 5.55465 142.244 6.02091 142.932 6.02091C143.553 6.02091 144.013 5.66815 144.013 5.06692C144.013 4.48409 143.58 4.11292 142.932 4.11292H142.466V3.31844H142.926C143.469 3.31844 143.92 2.97181 143.92 2.39818C143.92 1.8399 143.469 1.49327 142.936 1.49327C142.334 1.49327 141.957 1.90738 141.908 2.51475L140.93 2.35524C141.016 1.42885 141.72 0.665039 142.936 0.665039C144.161 0.665039 144.88 1.3583 144.88 2.22027C144.88 3.05463 144.241 3.42887 143.614 3.47795V3.83071C144.327 3.85525 145 4.30617 145 5.18962C145 6.12828 144.229 6.84608 142.936 6.84608C141.664 6.84914 140.868 6.13748 140.757 5.03318Z"
                fill="white"
              />
              <path
                d="M69.252 4.72964H59.7164V29.1808H55.0689V4.72964H45.5333V0.683594H69.252V4.72964Z"
                fill="white"
              />
              <path
                d="M65.7958 18.708C65.7958 12.0975 69.6409 7.67725 75.6924 7.67725C81.5805 7.67725 85.6693 11.6988 85.6693 18.389V18.6283C85.6693 19.0669 85.6693 19.4227 85.6292 19.6651H70.1223C70.0822 23.5271 72.4059 26.2357 76.1337 26.2357C78.8586 26.2357 80.5004 25.1222 81.062 22.8921H85.3885C85.1478 25.9565 82.5433 29.6621 76.1337 29.6621C69.8446 29.659 65.7958 25.2418 65.7958 18.708ZM81.2626 16.4381C81.1824 13.8092 79.0592 11.0607 75.6924 11.0607C72.446 11.0607 70.2828 13.8092 70.2025 16.4381H81.2626Z"
                fill="white"
              />
              <path
                d="M93.4243 8.15564V11.3029H93.5848C94.7081 9.19246 96.9917 7.68018 99.8338 7.68018C100.516 7.68018 101.158 7.75993 101.676 7.87956V12.0238C100.914 11.9041 100.235 11.8612 99.6332 11.8612C95.6246 11.8612 93.6218 13.9318 93.6218 18.1158V29.1865H89.2953V8.15871H93.4243V8.15564Z"
                fill="white"
              />
              <path
                d="M108.573 8.15564V11.3029H108.734C109.857 9.19246 112.141 7.68018 114.983 7.68018C115.665 7.68018 116.307 7.75993 116.825 7.87956V12.0238C116.063 11.9041 115.384 11.8612 114.782 11.8612C110.774 11.8612 108.771 13.9318 108.771 18.1158V29.1865H104.444V8.15871H108.573V8.15564Z"
                fill="white"
              />
              <path
                d="M133.079 29.1833C132.598 28.6679 132.277 27.5514 132.277 26.4747V26.3949C130.996 28.5851 128.07 29.6618 125.225 29.6618C121.38 29.6618 118.214 27.1925 118.214 23.3704C118.214 19.5881 120.538 17.358 125.145 16.7599L129.191 16.2445C131.314 15.9654 132.236 15.2077 132.236 13.6157C132.236 12.1433 130.712 11.1064 128.148 11.1064C125.383 11.1064 123.54 12.1433 123.38 14.493H119.174C119.133 10.0328 122.741 7.68311 128.148 7.68311C133.477 7.68311 136.563 9.87331 136.563 13.8151V25.8428C136.563 27.3949 136.683 28.5913 137.285 29.1864H133.079V29.1833ZM126.546 19.8243C123.901 20.22 122.581 21.3765 122.581 23.3274C122.581 25.2784 123.901 26.355 126.105 26.355C129.03 26.355 132.236 24.6434 132.236 20.901V18.5114C131.916 18.8304 130.876 19.1495 129.07 19.4286L126.546 19.8243Z"
                fill="white"
              />
              <path
                d="M37.9359 0.684082H0.0032959V4.73013H37.9359V0.684082Z"
                fill="white"
              />
              <path
                d="M28.4896 9.75077C22.4318 6.68632 15.7446 6.60964 9.65905 9.53604L8.71475 9.99004C6.40954 11.1005 3.99015 11.6189 1.6047 11.6189C1.21587 11.6189 0.669653 11.6465 0 11.6189V15.6803C0.59559 15.7017 1.13563 15.7171 1.51212 15.7171C4.64128 15.7171 7.7365 14.9901 10.7145 13.5606L11.6588 13.1066C16.3988 10.8244 21.4011 10.8827 26.2708 13.3459C29.3752 14.9165 32.6155 15.7171 35.9021 15.7171C36.3896 15.7171 37.1241 15.6956 37.9357 15.6649V11.6127C37.1704 11.6404 36.476 11.6618 36.0378 11.6618C33.5351 11.6649 31.0448 11.0453 28.4896 9.75077Z"
                fill="white"
              />
              <path
                d="M30.0666 20.8707C26.0764 15.3553 21.8209 14.1621 18.9571 14.1283C16.0748 14.0915 11.9273 15.1652 7.931 20.4321L7.25826 21.3124C5.4252 23.699 2.8114 25.0579 0.00317383 25.199V29.1929C4.2834 29.015 8.27046 26.9843 11.1065 23.2971L11.7082 22.5057C13.8962 19.6253 16.2477 18.1621 18.7627 18.1437C21.25 18.1713 23.7342 19.8002 25.9283 22.8309L26.0949 23.0579C28.9803 26.9597 33.3562 29.1622 37.9358 29.2174V25.2235C34.9362 25.1407 32.1126 23.6376 30.0666 20.8707Z"
                fill="white"
              />
            </svg>
          </Link>
          <button
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navigation-header"
            aria-controls="navigation-header"
            aria-expanded="false"
            aria-label="Toggle navigation"
            className="navbar-toggler"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 30 30"
              height="1.5em"
              width="1.5em"
            >
              <path
                stroke="rgba(255, 255, 255, 0.55)"
                strokeLinecap="round"
                strokeMiterlimit="10"
                strokeWidth="2"
                d="M4 7h22M4 15h22M4 23h22"
              />
            </svg>
          </button>
          <div className="collapse navbar-collapse" id="navigation-header">
            <div className={cn('navbar-nav ms-auto', styles.navbar)}>
              {_.map(
                _.get(
                  props,
                  'pageContext.site.siteMetadata.header.nav_links',
                  null,
                ),
                (link) => (
                  <Link
                    className={cn('nav-link', styles.link)}
                    aria-current="page"
                    to={linkToURL({ link, language, path: props.path })}
                  >
                    {t(link.label)}
                  </Link>
                ),
              )}
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};
