// extracted by mini-css-extract-plugin
export var buttonContainer = "cards-section-module--buttonContainer--2JPo7";
export var card = "cards-section-module--card--mSwCe";
export var cardOffset = "cards-section-module--cardOffset--k9uaU";
export var cards = "cards-section-module--cards--Ye3RU";
export var container = "cards-section-module--container--kh6rS";
export var image = "cards-section-module--image--Tnhrs";
export var imageContainer = "cards-section-module--imageContainer--3st75";
export var info = "cards-section-module--info--BscW9";
export var name = "cards-section-module--name--uf2Fy";
export var subtitle = "cards-section-module--subtitle--cyj4X";
export var text = "cards-section-module--text--XfywC";
export var title = "cards-section-module--title---hMRk";