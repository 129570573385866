import React from 'react';
import cn from 'classnames';
import * as styles from './reviews-section.module.scss';
import { useTranslation } from 'react-i18next';

export const ReviewsSection = (props) => {
  const { section } = props;
  const { t } = useTranslation();

  return (
    <div className="bg-grey" id={t(section.id)}>
      <div className="container">
        <div className={cn('row', styles.container)}>
          {section.title && (
            <div className="col-lg-12">
              <h2 className={styles.title}>{t(section.title)}</h2>
            </div>
          )}
          <div className="col-lg-12">
            <div className={styles.cards}>
              {section.list.map((item) => (
                <div className={styles.card}>
                  <div className={styles.info}>
                    <div className={styles.imageContainer}>
                      <img
                        loading="lazy"
                        src={item.image}
                        className={styles.image}
                      />
                    </div>
                    {item.title && (
                      <div className={styles.name}>{t(item.title)}</div>
                    )}
                  </div>
                  {item.subtitle && (
                    <div className={styles.content}>{t(item.subtitle)}</div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
