// extracted by mini-css-extract-plugin
export var container = "product-section-module--container--S4t1i";
export var description = "product-section-module--description--GFNUC";
export var image = "product-section-module--image--cgho0";
export var indicator = "product-section-module--indicator--9-COW";
export var indicatorActive = "product-section-module--indicatorActive--OkdA1";
export var indicators = "product-section-module--indicators--FNnr3";
export var name = "product-section-module--name--v5136";
export var spec = "product-section-module--spec--PgviJ";
export var specText = "product-section-module--specText--CN7Tg";
export var specs = "product-section-module--specs--1YjOa";
export var title = "product-section-module--title--aL+tp";