// extracted by mini-css-extract-plugin
export var card = "people-section-module--card--3fH0C";
export var cards = "people-section-module--cards--hhfH9";
export var cardsOdd = "people-section-module--cardsOdd--rgsYD";
export var container = "people-section-module--container--2g1Qj";
export var image = "people-section-module--image--BJbGH";
export var info = "people-section-module--info--2j-dN";
export var name = "people-section-module--name--DIdi0";
export var nameLong = "people-section-module--nameLong--ga8+m";
export var position = "people-section-module--position--YVrJF";
export var title = "people-section-module--title--iCij8";