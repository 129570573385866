import React, { useContext } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import * as styles from './header-section.module.scss';
import { LanguageContext } from './Layout';
import { Link } from 'gatsby';
import { localizeURL } from '../utils/url';

export const HeaderSection = (props) => {
  const { section } = props;
  const { t } = useTranslation();
  const language = useContext(LanguageContext);

  return (
    <div className="bg-grey overflow-hidden" id={t(section.id)}>
      <div className="container">
        <div className={cn('row align-items-center', styles.container)}>
          <div className="col-lg-5">
            {section.title && (
              <h2 className={styles.title}>{t(section.title)}</h2>
            )}
            {section.subtitle && (
              <p className={styles.subtitle}>{t(section.subtitle)}</p>
            )}
            {section.buttonLink && section.buttonText && (
              <Link
                to={localizeURL(language, section.buttonLink)}
                className={cn('btn btn-primary', styles.button)}
              >
                {t(section.buttonText)}
              </Link>
            )}
          </div>
          <div className="col-lg-6 offset-lg-1">
            <img
              loading="lazy"
              src={section.image}
              alt=""
              className={cn(styles.image)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
