import React, { useContext } from 'react';
import cn from 'classnames';
import * as styles from './product-section.module.scss';
import { useTranslation } from 'react-i18next';
import { LanguageContext } from './Layout';
import { Link } from 'gatsby';
import { localizeURL } from '../utils/url';

export const ProductSection = (props) => {
  const { section } = props;
  const { t } = useTranslation();
  const language = useContext(LanguageContext);

  return (
    <div className="bg-white" id={t(section.id)}>
      <div className="container">
        <div className={cn('row', styles.container)}>
          <div className="col-lg-10 offset-lg-1">
            {section.title && (
              <h2 className={styles.title}>{t(section.title)}</h2>
            )}
          </div>
          <div className="col-lg-6">
            <img
              src={section.image}
              alt=""
              className={cn('w-100 img-fluid', styles.image)}
            />
            <div className={styles.indicators}>
              {/* No other images so no indicator */}
              {/* <div className={cn(styles.indicator, styles.indicatorActive)} />
              <div className={styles.indicator} />
              <div className={styles.indicator} /> */}
            </div>
          </div>
          <div className="col-lg-5 offset-lg-1 d-flex flex-column">
            {section.heading && (
              <div className={cn('mt-4 mt-lg-0', styles.name)}>
                {t(section.heading)}
              </div>
            )}
            {section.subtitle && (
              <div className={styles.description}>{t(section.subtitle)}</div>
            )}
            <div className={styles.specs}>
              {section.list.map((item) => (
                <div className={styles.spec}>
                  {item.text && (
                    <div className={styles.specText}>{t(item.text)}</div>
                  )}
                  {item.value && (
                    <div className={styles.specText}>{t(item.value)}</div>
                  )}
                </div>
              ))}
            </div>
            {section.buttonLink && section.buttonText && (
              <div className={cn('mt-auto', styles.buttonContainer)}>
                <Link
                  to={localizeURL(language, section.buttonLink)}
                  className={cn('btn btn-primary', styles.button)}
                >
                  {t(section.buttonText)}
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
