import React, { useContext } from 'react';
import cn from 'classnames';
import * as styles from './services-section.module.scss';
import { useTranslation } from 'react-i18next';
import { LanguageContext } from './Layout';
import { Link } from 'gatsby';
import { localizeURL } from '../utils/url';

export const ServicesSection = (props) => {
  const { section } = props;
  const { t } = useTranslation();
  const language = useContext(LanguageContext);

  return (
    <div className="bg-grey" id={t(section.id)}>
      <div className="container">
        <div className={cn('row', styles.container)}>
          <div className="col-lg-12">
            {section.title && (
              <h2 className={styles.title}>{t(section.title)}</h2>
            )}
          </div>
          {section.list.map((item) => (
            <div className="col-lg-4 d-flex">
              <div className={styles.card}>
                <div className={styles.icon}>
                  <img src={item.image} alt="" className="img-fluid" />
                </div>
                <div className={styles.info}>
                  {item.title && (
                    <div className={styles.name}>{t(item.title)}</div>
                  )}
                  {item.subtitle && (
                    <div className={styles.text}>{t(item.subtitle)}</div>
                  )}
                </div>
              </div>
            </div>
          ))}
          <div className="col-lg-12">
            {section.buttonLink && section.buttonText && (
              <div className={styles.buttonContainer}>
                <Link
                  to={localizeURL(language, section.buttonLink)}
                  className={cn('btn btn-primary', styles.button)}
                >
                  {t(section.buttonText)}
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
