import '../sass/main.scss';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import _ from 'lodash';

import { withPrefix, attribute } from '../utils';
import Header from './Header';
import Footer from './Footer';
import { I18nextProvider } from 'react-i18next';
import i18n from '../../i18n';

export const LanguageContext = React.createContext('en');

export default function Body(props) {
  const language = _.get(props, 'pageContext.lang', 'en');

  useEffect(() => {
    i18n.changeLanguage(language)
  }, [language])

  return (
    <React.Fragment>
      <Helmet>
        <html lang={_.get(props, 'pageContext.lang', 'en')} />
        <title>
          {_.get(props, 'pageContext.frontmatter.seo.title', null)
            ? _.get(props, 'pageContext.frontmatter.seo.title', null)
            : _.get(props, 'pageContext.frontmatter.title', null) +
            ' | ' +
            _.get(props, 'pageContext.site.siteMetadata.title', null)}
        </title>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initialScale=1.0"
        />
        <meta name="google" content="notranslate" />
        <meta
          name="description"
          content={
            _.get(
              props,
              'pageContext.frontmatter.seo.description',
              null,
            ) || ''
          }
        />
        {_.get(props, 'pageContext.frontmatter.seo.robots', null) && (
          <meta
            name="robots"
            content={_.join(
              _.get(props, 'pageContext.frontmatter.seo.robots', null),
              ',',
            )}
          />
        )}
        {_.map(
          _.get(props, 'pageContext.frontmatter.seo.extra', null),
          (meta, meta_idx) => {
            let key_name = _.get(meta, 'keyName', null) || 'name';
            return _.get(meta, 'relativeUrl', null) ? (
              _.get(
                props,
                'pageContext.site.siteMetadata.domain',
                null,
              ) &&
              (() => {
                let domain = _.trim(
                  _.get(
                    props,
                    'pageContext.site.siteMetadata.domain',
                    null,
                  ),
                  '/',
                );
                let rel_url = withPrefix(_.get(meta, 'value', null));
                let full_url = domain + rel_url;
                return (
                  <meta
                    key={meta_idx}
                    {...attribute(key_name, _.get(meta, 'name', null))}
                    content={full_url}
                  />
                );
              })()
            ) : (
              <meta
                key={meta_idx + '.1'}
                {...attribute(key_name, _.get(meta, 'name', null))}
                content={_.get(meta, 'value', null)}
              />
            );
          },
        )}
        {_.get(props, 'pageContext.site.siteMetadata.favicon', null) && (
          <link
            rel="icon"
            href={withPrefix(
              _.get(
                props,
                'pageContext.site.siteMetadata.favicon',
                null,
              ),
            )}
          />
        )}
        {/* <link
            href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.1/dist/css/bootstrap.min.css"
            rel="stylesheet"
            integrity="sha384-+0n0xVW2eSR5OomGNYDnhzAbDsOXxcvSN1TPprVMTNDbiYZCxYbOOl7+AMvyTG2x"
            crossorigin="anonymous"
          ></link> */}
        {/* <script
            src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.1/dist/js/bootstrap.bundle.min.js"
            integrity="sha384-gtEjrD/SeCtmISkJkNUaaKMoLD0//ElJ19smozuHV6z3Iehds+3Ulb9Bn9Plx0x4"
            crossorigin="anonymous"
          ></script> */}
      </Helmet>
      <I18nextProvider i18n={i18n}>
        <LanguageContext.Provider value={language}>
          <Header {...props} />
          {props.children}
          <Footer {...props} />
        </LanguageContext.Provider>
      </I18nextProvider>
    </React.Fragment>
  );
}

