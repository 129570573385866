import React from 'react';
import cn from 'classnames';
import * as styles from './people-section.module.scss';
import { useTranslation } from 'react-i18next';

export const PeopleSection = (props) => {
  const { section } = props;
  const isOdd = section.list?.length % 2 === 1;
  const { t } = useTranslation();

  return (
    <div className="bg-white" id={t(section.id)}>
      <div className="container">
        <div className={cn('row', styles.container)}>
          <div className="col-lg-12">
            {section.title && (
              <h2 className={styles.title}>{t(section.title)}</h2>
            )}
          </div>
          <div className="col-lg-12">
            <div className={cn(styles.cards, isOdd && styles.cardsOdd)}>
              {section.list.map((item) => (
                <div>
                  <div className={styles.card}>
                    <img
                      loading="lazy"
                      src={item.image}
                      alt=""
                      className={cn(styles.image)}
                    />
                    <div className={styles.info}>
                      {item.title && (
                        <div
                          className={cn(
                            styles.name,
                            item.title.length > 24 && styles.nameLong,
                          )}
                        >
                          {t(item.title)}
                        </div>
                      )}
                      {item.subtitle && (
                        <div className={styles.position}>
                          {t(item.subtitle)}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
