import React, { useContext } from 'react';
import _ from 'lodash';
import cn from 'classnames';
import * as styles from './footer.module.scss';
import { useTranslation } from 'react-i18next';
import { LanguageContext } from './Layout';
import { linkToURL } from '../utils/url';

export default (props) => {
  const { t } = useTranslation();

  const language = useContext(LanguageContext);

  return (
    <div className={styles.container}>
      <div className="container">
        <div className="row">
          <div className="col-md-2">
            <div className={styles.title}>
              {t(
                _.get(
                  props,
                  'pageContext.site.siteMetadata.footer.title',
                  null,
                ),
              )}
            </div>
            {_.map(
              _.get(
                props,
                'pageContext.site.siteMetadata.footer.nav_links',
                null,
              ),
              (link) => (
                <a
                  href={linkToURL({ link, language, path: props.path })}
                  className={styles.link}
                >
                  {t(link.label)}
                </a>
              ),
            )}
          </div>
          <div className="offset-md-8 col-md-2">
            <div className={styles.socialLinks}>
              {_.map(
                _.get(
                  props,
                  'pageContext.site.siteMetadata.footer.social_links',
                  null,
                ),
                (link) => (
                  <a
                    href={linkToURL({ link, language, path: props.path })}
                    target="_blank"
                    className={styles.socialLink}
                  >
                    <img
                      className={styles.socialLogo}
                      src={`/images/${link.icon}.svg`}
                      alt=""
                    />
                  </a>
                ),
              )}
            </div>
          </div>
        </div>
        <div className={cn('row', styles.copyContainer)}>
          <div className="col-md-4">
            {_.map(
              _.get(props, 'pageContext.site.siteMetadata.footer.info', null),
              (item) => (
                <div className={styles.address}>{t(item)}</div>
              ),
            )}
          </div>
          <div className="col-md-3 offset-md-5">
            <div className="d-flex w-100 h-100">
              <div className={styles.copy}>
                {t(
                  _.get(props, 'pageContext.site.siteMetadata.footer.content'),
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
