export function linkToURL({ link, language, path }) {
	if (link.new_window) {
		return link.url
	}

	if (link.toggle_language) {
		// TODO: hardcoded language change
		return isDefault(language) ? `/lt${path}` : path.replace(`/lt`, '')
	}

	return localizeURL(language, link.url)
}

export function localizeURL(language, url) {
	if (url.includes('http')) {
		return url
	}

	if (isDefault(language)) {
		return url
	}

	return `/lt${url}`
}

function isDefault(language) {
	return language === 'en'
}