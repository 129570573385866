import React from 'react';
import cn from 'classnames';
import * as styles from './benefits-section.module.scss';
import { useTranslation } from 'react-i18next';

export const BenefitsSection = (props) => {
  const { section } = props;
  const { t } = useTranslation();

  return (
    <div className="bg-white" id={t(section.id)}>
      <div className="container">
        <div className={cn('row', styles.container)}>
          <div className="col-lg-5">
            {section.title && (
              <h2 className={styles.title}>{t(section.title)}</h2>
            )}
          </div>
          <div className="col-lg-6 offset-lg-1 mt-4">
            <div className={styles.items}>
              {section.list.map((item) => (
                <div className={styles.item}>
                  <div className={styles.icon}>
                    <svg
                      width="26"
                      height="27"
                      viewBox="0 0 26 27"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M25.5 13.3393C25.5 20.334 19.898 25.9941 13 25.9941C6.102 25.9941 0.5 20.334 0.5 13.3393C0.5 6.34471 6.102 0.68457 13 0.68457C19.898 0.68457 25.5 6.34471 25.5 13.3393Z"
                        stroke="#161616"
                      />
                      <rect
                        x="10"
                        y="10.1846"
                        width="5.76923"
                        height="5.76923"
                        fill="#548EFF"
                      />
                    </svg>
                  </div>
                  {item.text && (
                    <div className={styles.name}>{t(item.text)}</div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
