import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './locales/en.json';
import lt from './locales/lt.json';

function translationsListToI18nMap(language) {
  if (!language) {
    return;
  }

  const map = {};
  const translations = language.translations ?? [];

  for (const { key, value } of translations) {
    map[key] = value;
  }

  return { translation: map };
}

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    detection: {
      order: ['path', 'htmlTag'],
    },
    resources: {
      en: translationsListToI18nMap(en),
      lt: translationsListToI18nMap(lt),
    },
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
